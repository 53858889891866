const axios =  require("axios");
 
// PULL FROM MODX SYSTEM_SETTINGS
const configJSON = window.fbp_config_json;
 
const config = JSON.parse(configJSON);
// console.log(config)  
 

const getHistory = async () => {
 

    let fbPostsWidget = window.document.getElementById('fbPostsWidget');

    if(fbPostsWidget){

      let pinned = [];
      let hidden = [];

      if(Array.isArray(config.saved_posts)){
        // FIRST LETS LOOK FOR PINNED POSTS. AND GET THEM.
        pinned = config.saved_posts.filter(post => parseInt(post.pin) === 1 && parseInt(post.hide) !== 1 );
        hidden = config.saved_posts.filter(post => parseInt(post.hide) === 1 );
      }
        let display = [];

        // console.log(`Pinned posts found : ${pinned.length}`);
        // console.log(pinned);

        // GET PINNED POSTS
        for(n=0; n<pinned.length; n++){ 
            let pinnedPost = await getSinglePost(pinned[n].post_id);
            if(!pinnedPost.story || pinnedPost.is_hidden || pinnedPost.is_expired ){
                display.push(pinnedPost);
            }
            
        }
          

        // console.log(hidden);

        // RECENT POSTS, AND HIDE HIDDEN
        const historyResponse = await axios.get(`https://graph.facebook.com/${config.api_version}/petrgatinaismontargois/feed`,{
            params:{
                access_token: config.access_token,
                fields: "permalink_url,is_hidden,is_expired,story",
            },
        });
        
        let recentPosts = historyResponse.data.data;

        // console.log("**********************");
        // console.log("RECENT POSTS: " , recentPosts.length);

        recentPosts.forEach(p => {
            let foundInHidden = hidden.find(h => h.post_id === p.id);
            if(p.story){
                // console.log(p);
            }
             if(!foundInHidden){
                display.push(p);
             }
        });  

        let finalSort = display.filter(item => {
            return (!item.story || item.is_hidden || item.is_expired );
        })
  
        // console.log("*******************");
        // console.log(`Pinned posts found : ${pinned.length}`);
        // console.log(`Hidden posts found : ${hidden.length}`);

        let max_shown = parseInt(config.how_many) + pinned.length;

        // console.log(`DISPLAY ${max_shown} posts out of ${finalSort.length} `);
        // console.log(display);

        for(let i=0; i<max_shown; i++){
        
                var post = finalSort[i];
                // console.log(post);
    
                // const embedCode = `<iframe id="${post.id}" src="https://www.facebook.com/plugins/post.php?href=${post.permalink_url}&show_text=true&appId=911749112232090 " width="100%"  height="100%" style="border:none; " scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
            
                let embedCode = `<div class="col-12 px-0 text-center mb-5 fb-post" data-lazy="true" data-width="750" data-show-text="true" data-href="${post.permalink_url}">
                ${post.permalink_url}
                </div>`;
                
                var existing = "";
                if(i !== 0){
                    existing =  fbPostsWidget.innerHTML;
                } 
        
                fbPostsWidget.innerHTML = existing + embedCode;

            
        }

    }


}


const getSinglePost = async (id) => {

    // console.log(`GET /${id} `)

    try {
      
      const response = await axios.get(`https://graph.facebook.com/${config.api_version}/${id}`, {
        params:{
            access_token: config.access_token,
            fields: "permalink_url",
        },
      });

      if (!response || response.error) {
        throw new Error('Error retrieving post');
      }
      
      // assign the response to a variable
      return response.data;
      // do something with the post data
    } catch (error) { 
      console.error(error); 
    }
};

window.fbAsyncInit = async function() { 

    await FB.init({
      appId            :  config.app_id,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : config.api_version,
    });

    // my code
    await getHistory(FB);

    FB.XFBML.parse( window.document.getElementById('fbPostsWidget') );

    
  };


// 
window.fbAsyncInit();